import React, { useEffect } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { PageVideo } from "../components/videos/page-video";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const Container = styled.div`
  width: 100%;
  max-width: 1400px;

  padding: 190px 100px 0 100px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    padding: 190px 50px 0 50px;
  }

  @media (max-width: 768px) {
    padding: 100px 0 0 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  max-width: 920px;

  margin: 0 auto;
`;

const Row = styled.div`
  width: 100%;

  margin: 0 auto 60px auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    margin: 0 auto 40px auto;
  }
`;

const Title = styled.div`
  width: 100%;
  max-width: 690px;

  margin: 0 auto;

  & h1 {
    font-size: 36px;
    line-height: 48px;

    letter-spacing: 0.01em;
    text-align: center;

    @media (max-width: 900px) {
      font-size: 26px;
      line-height: 30px;
    }
  }
`;

const Text = styled.div`
  width: 100%;
  max-width: 690px;

  margin: 0 auto;

  & h1,
  & h2,
  & h3,
  & p,
  & strong,
  & em {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.04em;

    @media (max-width: 1300px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & strong {
    font-weight: bold;
  }

  & em {
    font-family: "Caslon Doric Web Italic", "Helvetica", "Lucida Grande",
      sans-serif;
  }

  & a {
    text-decoration: underline;
    transition: 250ms color ease;

    &:hover {
      text-decoration: none;
      color: #df3f1c;
    }
  }

  & p:first-of-type {
    margin-top: 0;
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  & p:empty {
    display: inline-block;
    margin: 0;
  }

  & h1 {
    margin: 1em 0;
  }
`;

const Image = styled.div`
  margin: 0;

  & img {
    margin: 0 auto;

    &.large-image {
      max-width: 800px;

      &.portrait {
        max-width: 500px;
      }
    }

    &.small-image {
      max-width: 470px;
    }
  }

  & .image-caption {
    margin: 0 auto;
    text-align: center;

    & p {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.04em;
    }
  }
`;

const Video = styled.div`
  margin: 0;

  &.large-video {
    max-width: 800px;
  }

  &.small-video {
    max-width: 470px;
  }

  & .image-caption {
    margin: 0 auto;
    text-align: center;

    & p {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.04em;
    }
  }
`;

const ArticleContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("article");
  }, []);

  const content = data.prismicArticle.data.body.map((content, index) => {
    if (content.slice_type === "title") {
      return (
        <Row key={`single_article_${content.id}_row_${index}`}>
          <Title
            dangerouslySetInnerHTML={{
              __html: content.primary.article_title.html,
            }}
          />
        </Row>
      );
    }

    if (content.slice_type === "text") {
      return (
        <Row key={`single_article_${content.id}_row_${index}`}>
          <Text
            className="caslon-doric"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </Row>
      );
    }

    if (content.slice_type === "image") {
      return (
        <Row key={`single_article_${content.id}_row_${index}`}>
          <Image>
            {content.primary.image.fluid !== null && (
              <img
                className={`${ImageOrientation(
                  content.primary.image
                )} ${content.primary.image_size.toLowerCase()}-image`}
                srcSet={content.primary.image.fluid.srcSetWebp}
                src={content.primary.image.fluid.srcWebp}
                alt={content.primary.image.alt}
                loading="lazy"
              />
            )}

            <div
              className="image-caption caslon-doric"
              dangerouslySetInnerHTML={{
                __html: content.primary.image_caption.html,
              }}
            />
          </Image>
        </Row>
      );
    }

    if (content.slice_type === "video") {
      return (
        <Row key={`single_article_${content.id}_row_${index}`}>
          <Video
            className={`${content.primary.video_size.toLowerCase()}-video`}
          >
            <PageVideo
              width={content.primary.video.width}
              height={content.primary.video.height}
              embed_url={content.primary.video.embed_url}
            />
            <div
              className="image-caption caslon-doric"
              dangerouslySetInnerHTML={{
                __html: content.primary.video_caption.html,
              }}
            />
          </Video>
        </Row>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicArticle.data.title.text} – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `${data.prismicArticle.data.title.text} – Park Village`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicArticle.data.title.text} – Park Village`,
          },
        ]}
      />

      <Container>
        <ContentContainer>{content}</ContentContainer>
      </Container>
    </>
  );
};

const Article = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <ArticleContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPreview(Article);

export const query = graphql`
  query Article($uid: String!) {
    prismicArticle(uid: { eq: $uid }) {
      prismicId
      data {
        title {
          html
          text
        }
        body {
          ... on PrismicArticleBodyTitle {
            id
            slice_type
            primary {
              article_title {
                html
              }
            }
          }
          ... on PrismicArticleBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicArticleBodyImage {
            id
            slice_type
            primary {
              image {
                dimensions {
                  width
                  height
                }
                fluid {
                  srcSetWebp
                  srcWebp
                }
              }
              image_caption {
                html
              }
              image_size
            }
          }
          ... on PrismicArticleBodyVideo {
            id
            slice_type
            primary {
              video {
                html
                width
                height
                embed_url
              }
              video_caption {
                html
              }
              video_size
            }
          }
        }
      }
    }
  }
`;
