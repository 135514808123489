import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player/lazy";

// Components
// import "../styles/plyr.css";

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;

  & .player-wrapper {
    position: relative;
    padding-top: ${props => props.aspectRatio}%;
    /* Player ratio: 100 / (1280 / 720) */
  }

  & .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 200px;
  height: 200px;

  z-index: 3;

  &:hover {
    & button {
      opacity: 1;
    }
  }
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  transition: 250ms opacity ease;
  opacity: ${props => props.opacity};

  width: 140px;

  border: 1px solid rgba(250, 251, 246, 0.5);
  box-sizing: border-box;
  border-radius: 1px;

  font-family: "LDNBloomsburyOldStyle-DemiBold", Times, "Times New Roman", serif;
  font-style: normal;
  font-weight: 500;

  font-size: 24px;
  line-height: 60px;

  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.09em;

  color: rgba(250, 251, 246, 0.5);

  @media (max-width: 768px) {
    width: 100px;

    font-size: 18px;
    line-height: 40px;
  }
`;

export const PageVideo = ({
  video,
  embed_url,
  height,
  width,
  videoDetails,
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const aspectRatio = (height / width) * 100;

  return (
    <>
      <VideoWrapper
        className="video"
        height={height}
        width={width}
        aspectRatio={aspectRatio}
      >
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={embed_url}
            width="100%"
            height="100%"
            playing={isVideoPlaying}
          />

          <ButtonWrapper>
            <PlayButton
              onClick={() => setIsVideoPlaying(!isVideoPlaying)}
              opacity={isVideoPlaying ? 0 : 1}
            >
              {isVideoPlaying ? `Stop` : `Play`}
            </PlayButton>
          </ButtonWrapper>
        </div>
      </VideoWrapper>
    </>
  );
};
